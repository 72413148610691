.partnerUniversitiesPage {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;

	@media screen and (min-width: 730px) {
	}

	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}
.partnerUniversityContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;
	align-items: center;
}
.PartnerUniversitiesChild {
	width: 95%;
	border: 2px solid #f12a62;
	border-radius: 0.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem 0.5rem;
	position: relative;
	margin-top: 4.375rem;
	margin-bottom: 3.125rem;

	@media screen and (min-width: 730px) {
		padding: 3.125rem 0.3125rem;
	}

	@media screen and (min-width: 1000px) {
		width: 90%;
		padding: 3.125rem 1.25rem;
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}
.partnerUniHeading {
	position: absolute;
	top: -3%;
	background-color: white;
	padding: 1rem;
	font-size: 1.5rem;
	font-weight: 500;

	@media screen and (min-width: 730px) {
		font-size: 2rem;
		top: -4%;
	}

	@media screen and (min-width: 1000px) {
		top: -4%;
	}

	@media screen and (min-width: 1400px) {
		top: -4%;
	}

	@media screen and (min-width: 1700px) {
		top: -5%;
		font-size: 2rem;
	}
}
.partnerUniversitiesGridContainer {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: auto;
	gap: 0.5rem 0.31rem;

	@media screen and (min-width: 730px) {
		grid-template-columns: repeat(5, 1fr);
		grid-template-rows: auto;
		gap: 0.5rem;
	}
	@media screen and (min-width: 1000px) {
		gap: 0.8rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1rem;
	}
	@media screen and (min-width: 1400px) {
		gap: 1.2rem;
	}
}
.partnerUniversitiesContents {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0.31rem;
	box-shadow: 0px 0px 8px 0px #00000040;
	border-radius: 0.5rem;

	img {
		width: 100%;
		aspect-ratio: 3/2;
		object-fit: contain;

		@media screen and (min-width: 730px) {
			width: 5.5rem;
		}

		@media screen and (min-width: 1000px) {
			width: 6.82rem;
		}

		@media screen and (min-width: 1400px) {
			width: 9rem;
		}

		@media screen and (min-width: 1700px) {
			width: 12.18rem;
		}
	}

	@media screen and (min-width: 730px) {
	}

	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}
.partnerUniversitiesCard {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.universityName {
	font-size: 0.6rem;
	font-weight: 500;
	text-align: center;
	margin-top: 1.2rem;
	color: black;

	@media screen and (min-width: 730px) {
		font-size: 0.75rem;
		margin-top: 0.75rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 0.7rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 0.8rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 0.9rem;
	}
}

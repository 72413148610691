.button{
    width: 100%;
    border-radius: .4rem;
    padding: .5rem 1rem;
    background-color: #f12a62;
    color: var(--btn_text_color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 500;
    font-size: .75rem;
    user-select: none;

    @media screen and (min-width: 730px) {
        font-size: 1rem;
    }

    @media screen and (min-width: 1400px) {
        font-size: 1.21rem;
    }

    @media screen and (min-width: 1700px) {
        font-size: 1.3rem;
        padding: .8rem 1.1rem;
    }
}

.secondary{
    border-radius: .75rem;
    background: black;
}
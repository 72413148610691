.signUpFormContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	background-color: #fefefe;
	border: 2px solid #f12a62;
	height: max-content;
	border-radius: 0.625rem;
	padding: 0.75rem;

	@media screen and (min-width: 730px) {
		padding: 0.825rem 1.24rem;
	}

	@media screen and (min-width: 1000px) {
		padding: 1.3rem 2rem;
	}

	@media screen and (min-width: 1400px) {
		padding: 1.556rem 2.31rem;
	}

	@media screen and (min-width: 1700px) {
		padding: 1.8125rem 2.75rem;
	}
}
.signUpFormChild {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.inputWrapper {
	display: flex;
	flex-direction: column;

	input {
		border: 1px solid #dedede;
		padding: 0.43rem 1rem;
		border-radius: 8px;
		padding-left: 1rem;
		font-size: 1rem;
		background-color: #f7f7f7;
		outline: none;

		@media screen and (min-width: 730px) {
			padding: 0.33rem 0.625rem;
			font-size: 0.6875rem;
		}

		@media screen and (min-width: 1000px) {
			font-size: 0.65rem;
			padding: 0.6rem 0.8rem;
		}

		@media screen and (min-width: 1400px) {
			font-size: 0.875rem;
		}

		@media screen and (min-width: 1700px) {
			font-size: 1.063rem;
		}
	}
}
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}
label {
	font-size: 0.875rem;
	padding: 0.6875rem 0 0.16rem;

	@media screen and (min-width: 730px) {
		font-size: 0.7rem;
		padding: 0.875rem 0 0.21rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 0.9rem;
		padding: 0.9rem 0 0.3rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 0.8rem;
		padding: 1.06rem 0 0.268rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 0.95rem;
		padding: 1.3125rem 0 0.3125rem;
	}
}
.signUpformHeader {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.signUpHeading {
	font-size: 1.5625rem;
	span {
		color: var(--ziroh_primary_color);
	}
	@media screen and (min-width: 730px) {
		font-size: 1.2rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 1.8rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 2.25rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 2.67rem;
	}
}
.exitButton {
	outline: none;
	border: none;
	background: none;
	color: #aaaaaa;
	font-size: 1.4rem;

	@media screen and (min-width: 730px) {
		font-size: 1.44rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 1.675rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 2.625rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 3.188rem;
	}
}
.exit {
	display: none;
}
.buttonContainer {
	display: flex;
	justify-content: center;
}
.registerButton {
	display: flex;
	padding: 0.5rem 3rem;
	border-radius: 0.5rem;
	color: white;
	background-color: var(--ziroh_primary_color);
	border: none;
	margin-top: 1.15rem;
	width: 9.5rem;
	justify-content: center;
	cursor: pointer;

	@media screen and (min-width: 730px) {
		padding: 0.625rem;
		width: 8.875rem;
		margin-top: 1.11rem;
	}

	@media screen and (min-width: 1000px) {
		padding: 0.4rem 0.6rem;
		width: 7.875rem;
		margin-top: 1rem;
		font-size: 0.9rem;
		border-radius: 0.31rem;
	}

	@media screen and (min-width: 1400px) {
		width: 11.125rem;
		margin-top: 1.38rem;
		font-size: 0.875rem;
	}

	@media screen and (min-width: 1700px) {
		width: 13.3rem;
		margin-top: 1.68rem;
		font-size: 1.063rem;
	}
}

.registerButton:disabled {
	opacity: .6;
	cursor: not-allowed;
}

body,
html {
	font-family: 'Satoshi', 'Clash Display', sans-serif, monospace;
}

html{
	/* overflow-x: hidden; */
	scroll-behavior: smooth;
}

body {
	margin: 0;
	font-family: 'Satoshi';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

img{
	user-select: none;
	-ms-user-select: none;
	-ms-touch-select: none;
}

button{
	border: none;
	outline: none;
	cursor: pointer;
	font-family: 'Satoshi';
}

input, textarea{
	font-family: 'Satoshi';
}

a{
	text-decoration: none;
	cursor: pointer;
}

code {
	font-family: monospace;
}

.Toastify__toast-container {
	max-width: 60vw !important;
}

/* width */
::-webkit-scrollbar {
	width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgba(128, 128, 128, 0.287);
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(75, 75, 75, 0.871);
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #828282cd;
}

.notfound_page {
	padding: 1.5rem;
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: var(--pricing_card_primary_text_color);
	background-image: url(../../assets/gif/404-bg.gif);
	background-size: cover;
	background-repeat: no-repeat;
}

.container {
	background-image: url(../../assets/webp/binary_bg.webp);
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	background-size: cover;
	padding: 3rem 0;

	@media screen and (min-width: 730px) {
		width: 100%;
	}

	@media screen and (min-width: 1000px) {
		width: 80%;
		padding: 1.5rem 0;
	}

	@media screen and (min-width: 1400px) {
		background-size: cover;
		width: 70%;
		padding: 5rem 0;
	}

	@media screen and (min-width: 1700px) {
		padding: 8rem 0;
	}
}

figure {
	width: 100%;

	@media screen and (min-width: 730px) {
	}

	@media screen and (min-width: 1000px) {
		width: 85%;
	}

	@media screen and (min-width: 1400px) {
		width: 80%;
	}

	@media screen and (min-width: 1700px) {
	}

	img {
		width: 100%;
		-moz-user-select: none;
		-webkit-user-drag: none;
		-webkit-user-select: none;
	}
}

.notFound {
	font-weight: 600;
	font-size: 2rem;
	margin-top: -1rem;
	color: white;
	@media screen and (min-width: 730px) {
		font-size: 2.5rem;
		margin-top: -3rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 2.7rem;
		margin-top: -3.5rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 3rem;
		margin-top: -3.5rem;
	}

	@media screen and (min-width: 1700px) {
		margin-top: -4.5rem;
	}
}

.notFoundBtn {
	outline: none;
	border: none;
	border-radius: 1.6rem;
	padding: 1rem 1.5rem;
	background-color: var(--zunu_secondary_color);
	color: var(--text_color_white);
	cursor: pointer;
	font-weight: 500;
	margin-top: 1rem;
	font-size: 1rem;

	@media screen and (min-width: 730px) {
		margin-top: 1.5rem;
		font-size: 1.3rem;
	}

	@media screen and (min-width: 1000px) {
		margin-top: 2rem;
		font-size: 1.5rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 1.3rem;
		margin-top: 0.5rem;
	}

	@media screen and (min-width: 1700px) {
	}
}

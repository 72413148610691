.footer {
	width: 100%;
	max-width: 2500px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1.5rem 1.4375rem 0 1.4375rem;
	position: relative;
	overflow: hidden;
	background: black;
	gap: 2rem;

	@media screen and (min-width: 320px) {
		padding: 2rem 2.5rem 0 2.5rem;
	}

	@media screen and (min-width: 550px) {
		gap: 0;
		padding: 2rem 2.5rem 0 2.5rem;
	}

	@media screen and (min-width: 730px) {
		gap: 0;
		padding: 2rem 3.5rem 0 3.5rem;
	}

	@media screen and (min-width: 1024px) {
		gap: 0;
		padding: 4rem 5.5rem 0 5.5rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 0;
		padding: 3.82rem 9.7rem 0 9.7rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 0;
		padding: 5.1rem 12.7rem 0 12.7rem;
	}

	.footerHeader {
		width: 100%;
		display: flex;
		justify-content: space-between;
		//align-items: center;

		@media screen and (min-width: 550px) {
			justify-content: space-between;
		}
	}

	.headerLogoContainer {
		display: flex;
		flex-direction: column;
		z-index: 1;
		gap: 1.6rem;

		@media screen and (min-width: 320px) {
			display: flex;
			flex-direction: column-reverse;
			justify-content: space-between;
			gap: 1.6rem;
		}
		@media screen and (min-width: 730px) {
			flex-direction: row;
			justify-content: space-between;
			width: 100%;
		}
		@media screen and (min-width: 1000px) {
			flex-direction: row;
			justify-content: space-between;
			width: 100%;
		}
		@media screen and (min-width: 1400px) {
			flex-direction: row;
			justify-content: space-between;
			width: 100%;
		}
	}
	.zirohLogo {
		width: auto;
		height: 1rem;

		@media screen and (min-width: 320px) {
			height: 1.15rem;
		}

		@media screen and (min-width: 550px) {
			display: none;
			height: 1.5rem;
		}

		@media screen and (min-width: 730px) {
			display: flex;
			height: 1.5rem;
		}

		@media screen and (min-width: 1400px) {
			display: flex;
			height: 2rem;
		}
	}

	.zunuLogo {
		height: 1rem;

		@media screen and (min-width: 320px) {
			height: 1.15rem;
		}

		@media screen and (min-width: 550px) {
			height: 1.5rem;
		}

		@media screen and (min-width: 730px) {
			height: 1.5rem;
		}

		@media screen and (min-width: 1400px) {
			height: 2rem;
		}
	}

	@media screen and (min-width: 550px) {
		.flag {
			display: none;
		}
	}
	// .zunuLogo path {
	//     fill: var(--logo_color) !important;
	// }

	.linksContainer {
		margin: 1rem 0 2rem 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		gap: 1.5rem;

		@media screen and (min-width: 320px) {
			gap: 1.5rem;
		}

		@media screen and (min-width: 550px) {
			flex-direction: row;
			justify-content: space-between;
			gap: 1.2rem;
			margin: 3rem 0;
		}

		@media screen and (min-width: 730px) {
			flex-direction: row;
			justify-content: space-between;
			//	gap: 1.5rem;
			margin: 3rem 0;
		}

		@media screen and (min-width: 1200px) {
			//	gap: 3rem;
		}

		@media screen and (min-width: 1400px) {
			justify-content: space-between;
			margin: 5.5rem 0;
			//gap: 3rem;
		}

		a {
			text-decoration: none;
			color: var(--text_heading);
			color: #c7ceea;
			font-weight: 400;
			font-size: 0.75rem;
			cursor: pointer;

			@media screen and (min-width: 730px) {
				font-size: 0.85rem;
			}

			@media screen and (min-width: 1400px) {
				font-size: 1.2rem;
			}

			@media screen and (min-width: 1700px) {
				font-size: 1.5rem;
			}
		}
	}

	.privacyMediaContainer {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		gap: 1.5rem;
		padding-bottom: 2rem;
		z-index: 1;

		@media screen and (min-width: 550px) {
			flex-direction: column;
			gap: 1rem;
			padding-bottom: 2rem;
		}

		@media screen and (min-width: 730px) {
			flex-direction: row;
			gap: 0.87rem;
			padding-bottom: 5rem;
		}
		@media screen and (min-width: 1000px) {
			flex-direction: row;
			gap: 1.07rem;
			padding-bottom: 6rem;
		}
		@media screen and (min-width: 1400px) {
			flex-direction: row;
			gap: 1.5rem;
			padding-bottom: 8rem;
		}
		@media screen and (min-width: 1700px) {
			flex-direction: row;
			gap: 2rem;
			padding-bottom: 9rem;
		}
	}
	.privacyLogoContainer {
		//margin-bottom: 4.5rem;
		justify-content: center;
		font-weight: 600;
		color: #ffffff;
		font-size: 1.2rem;
		//gap: 1rem;

		@media screen and (min-width: 550px) {
			font-size: 1.3rem;
		}

		@media screen and (min-width: 730px) {
			font-size: 1rem;
		}

		@media screen and (min-width: 1000px) {
			//	margin-bottom: 6rem;
			font-size: 1.3rem;
		}

		@media screen and (min-width: 1400px) {
			//	margin-bottom: 10rem;
			font-size: 1.7rem;
		}

		@media screen and (min-width: 1700px) {
			font-size: 2.3rem;
			//	margin-bottom: 15rem;
		}

		span {
			background: var(--zunu_primary_color);
		}

		img {
			width: 100%;
			aspect-ratio: 16/9;
		}
		p {
			display: flex;
			//flex-direction: row-reverse;
			justify-content: end;
			font-size: 0.875rem;
			color: var(--Global-White, #fff);

			@media screen and (min-width: 320px) {
				font-size: 0.43669rem;
			}
			@media screen and (min-width: 730px) {
				font-size: 0.38006rem;
			}
			@media screen and (min-width: 1000px) {
				font-size: 0.46644rem;
			}
			@media screen and (min-width: 1400px) {
				font-size: 0.65594rem;
			}
			@media screen and (min-width: 1700px) {
				font-size: 0.875rem;
			}
		}
	}
	.mediaIconContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 1rem;

		a {
			z-index: 1;
		}

		@media screen and (min-width: 550px) {
			gap: 1rem;
		}

		@media screen and (min-width: 730px) {
			gap: 1.14rem;
		}

		@media screen and (min-width: 1000px) {
			gap: 1.4rem;
		}

		@media screen and (min-width: 1400px) {
			gap: 1.97rem;
		}

		@media screen and (min-width: 1700px) {
			gap: 2.62rem;
		}
	}
	.mediaIcons {
		display: flex;
		align-items: center;
		height: 1.3rem;
		z-index: 1;

		@media screen and (min-width: 550px) {
			height: 1.5rem;
		}

		@media screen and (min-width: 730px) {
			height: 1rem;
		}

		@media screen and (min-width: 1000px) {
			height: 1.3rem;
		}

		@media screen and (min-width: 1400px) {
			height: 1.5rem;
		}

		@media screen and (min-width: 1700px) {
			height: 1.8rem;
		}
	}

	.copyright {
		position: absolute;
		margin: auto;
		bottom: 5px;
		color: #ffffff;
		font-size: 0.8rem;

		@media screen and (min-width: 1700px) {
			font-size: 1.2rem;
		}
	}

	.design {
		position: absolute;
		width: 100%;
		bottom: 0;
		left: 0;
		right: 0;
		opacity: 0.6;
		object-fit: cover;
		pointer-events: none;
		z-index: 0;

		@media screen and (min-width: 550px) {
			bottom: -20px;
		}

		@media screen and (min-width: 1200px) {
			bottom: -45px;
		}

		@media screen and (min-width: 1400px) {
			bottom: -25px;
		}
		@media screen and (min-width: 1700px) {
			bottom: -0px;
		}
	}

	.design path {
		stroke: var(--logo_color) !important;
	}
}

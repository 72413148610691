.academicAlliancePage {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;

	@media screen and (min-width: 550px) {
	}

	@media screen and (min-width: 730px) {
	}

	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}
.academicPageIntro {
	display: flex;
	width: 100%;
	// align-items: ;
	justify-content: center;
	width: 100%;
	height: 90vh;
	background: transparent;
	background-image: url(../../assets//webp/academic-alliance-mobile.webp);
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;

	@media screen and (min-width: 730px) {
		background-image: url(../../assets/png/academic-alliance-desktop.png);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		align-items: center;
		justify-content: center;
	}
	@media screen and (min-width: 1000px) {
		// height:50vh;
		height: 90vh;
	}
	@media screen and (min-width: 1400px) {
		height: 100vh;
	}
}

.academicChildContainer {
	display: flex;
	flex-direction: row;
	width: 85%;
}

.academicContents {
	display: flex;
	flex-direction: column;
	width: 90%;
	top: 10%;
	padding-top: 5.5rem;

	@media screen and (min-width: 730px) {
		width: 50%;
		padding-top: 0;
	}
}
.academicHeader {
	font-size: 1.881rem;
	font-weight: 500;
	color: #f12a62;

	@media screen and (min-width: 730px) {
		font-size: 1.88rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 3.25rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 3.5rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 4.25rem;
	}
}
.academicSubText {
	font-size: 1.34rem;
	text-align: left;
	padding: 0.375rem 0;

	@media screen and (min-width: 730px) {
		font-size: 1.45rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 2.2rem;
	}

	@media screen and (min-width: 1400px) {
		padding: 1.625rem 0;
		font-size: 2.625rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 3.18rem;
	}
}
.registerButton {
	padding: 0.625rem 1.25rem;
	background-color: #f12a62;
	border-radius: 0.31rem;
	border: none;
	outline: none;
	color: white;
	width: 12rem;
	font-size: 0.7rem;
	margin-top: 0.625rem;
	cursor: pointer;

	@media screen and (min-width: 730px) {
		font-size: 0.6875rem;
		width: 12rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 1rem;
		width: 20rem;
	}

	@media screen and (min-width: 1400px) {
		width: 23.5rem;
		font-size: 1.05625rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 1.275rem;
	}
}

.academicProgram {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 2.5rem 0;
}
.academicProgramContainer {
	display: flex;
	flex-direction: column;
	width: 86%;
	justify-content: center;
	align-items: center;
}
.academicProgramHeading {
	font-size: 1.2rem;
	font-weight: 500;
	span {
		color: var(--ziroh_primary_color);
		font-weight: 500;
	}

	@media screen and (min-width: 730px) {
		font-size: 1.6875rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 2.3rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 2.25rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 2.656rem;
	}
}
.academicProgramText {
	font-size: 0.8rem;
	margin-top: 0.5rem;
	width: 95%;
	text-align: center;

	@media screen and (min-width: 730px) {
		width: 80%;
		font-size: 0.6875rem;
		margin-top: 0.625rem;
	}

	@media screen and (min-width: 1000px) {
		width: 60%;
		font-size: 0.9rem;
		margin-top: 1.25rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 0.81rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 0.975rem;
	}
}

.academicProgramChild {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding-top: 1rem;

	@media screen and (min-width: 730px) {
		width: 90%;
		padding-top: 2.5rem;
	}
	@media screen and (min-width: 1000px) {
		width: 80%;
		padding-top: 3.75rem;
	}
	@media screen and (min-width: 1400px) {
		padding-top: 3.75rem;
	}
	@media screen and (min-width: 1700px) {
	}
}

.programGridContainer {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	width: 100%;
	gap: 0.825rem;

	@media screen and (min-width: 730px) {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 1.875rem;
	}

	@media screen and (min-width: 1000px) {
		width: 90%;
	}
}

.section2Icons {
	height: 1.2rem;
	margin-right: 0.75rem;

	@media screen and (min-width: 730px) {
		height: 1rem;
	}

	@media screen and (min-width: 1000px) {
		height: 1.6rem;
	}

	@media screen and (min-width: 1400px) {
		height: 1.8rem;
	}

	@media screen and (min-width: 1700px) {
		height: 2rem;
	}
}

.academicProgramContents {
	display: flex;
	flex-direction: column;
	transition: all 0.5s ease-in-out;
	border-radius: 8px;
	box-shadow: 0px 0px 6px 0px #00000040;
	background-color: #fafafa;
	padding: 1rem;

	@media screen and (min-width: 730px) {
		padding: 0.625rem;
	}
	@media screen and (min-width: 1000px) {
		padding: 1.25rem;
	}
	@media screen and (min-width: 1400px) {
	}
	@media screen and (min-width: 1700px) {
	}
}
.programHeaderContainer {
	display: flex;
	align-items: center;
}
.programGridHeader {
	font-size: 0.9rem;
	font-weight: 500;
	position: relative;
	width: 100%;
	margin: 0.375rem;
	padding-bottom: 0.31rem;

	@media screen and (min-width: 730px) {
		font-size: 0.75rem;
		margin: 0.625rem 0;
	}

	@media screen and (min-width: 1000px) {
		font-size: 1.1rem;
		padding-bottom: 0.625rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 0.875rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 1.063rem;
	}
}
.programGridHeader::before {
	border-bottom: 2px solid #f12a62;
	content: '';
	position: absolute;
	color: #f12a62;
	left: 0;
	right: 70%;
	bottom: 0;
}
.programGridText {
	font-size: 0.79rem;

	@media screen and (min-width: 730px) {
		font-size: 0.625rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 0.8rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 0.8rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 0.975rem;
	}
}
.campusMOUPartners {
	background-color: #fafafa;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.campusMOUChild {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
}
.campusMOUHeading {
	font-size: 1.2rem;
	font-weight: 500;
	color: black;
	margin-top: 2rem;

	@media screen and (min-width: 730px) {
		font-size: 1.6875rem;
		margin-top: 2.5rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 2rem;
		margin-top: 3.125rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 2.25rem;
		// margin-top: ;
	}

	@media screen and (min-width: 1700px) {
		font-size: 2.656rem;
	}
}
.marqueeContainer {
	display: flex;
	width: 100%;
	height: 100%;
	padding: 0.625rem 0;
	margin: 1.875rem 0;
	// margin: 1.875rem 0;
}

Marquee {
	gap: 4rem;
}
.carouselimgText {
	display: flex;
	width: 100%;
}
.marqueeContents {
	display: flex;
	width: 8.125rem;
	height: 100%;
	position: relative;
	border-radius: 0.5rem;
	overflow: hidden;
	margin-right: 0.5rem;

	@media screen and (min-width: 730px) {
		width: 14.25rem;
		margin-right: 2rem;
	}

	@media screen and (min-width: 1000px) {
		width: 22rem;
	}
	@media screen and (min-width: 1400px) {
		width: 27.93rem;
	}
	@media screen and (min-width: 1700px) {
		width: 34rem;
	}

	img {
		height: 100%;
		width: 100%;
		border-radius: 0.5rem;
		aspect-ratio: 5 / 3;
		object-fit: cover;
	}
}
.universityNames {
	position: absolute;
	color: white;
	font-weight: 500;
	bottom: 0;
	top: 0%;
	padding: 0 0 0.33rem 0.33rem;
	width: 100%;
	display: flex;
	align-items: flex-end;
	background: linear-gradient(
		0deg,
		rgba(0, 0, 0, 0.6) 0%,
		rgba(0, 0, 0, 0) 100%
	);
	opacity: 1;
	transition: all 0.3s linear;
	font-size: 0.537rem;

	@media screen and (min-width: 730px) {
		font-size: 0.6875rem;
		padding: 0 0 0.42rem 0.42rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 0.8rem;
		padding: 0 0 0.58rem 0.58rem;
	}
	@media screen and (min-width: 1400px) {
		font-size: 1.06rem;
		padding: 0 0 0.84rem 0.84rem;
	}
	@media screen and (min-width: 1700px) {
		font-size: 1.276rem;
		padding: 0 0 1.03rem 1.03rem;
	}
}

.marqueeImagesContainer {
	display: flex;
}

.ewlCertificateContainer {
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin: 1.25rem 0;

	@media screen and (min-width: 730px) {
		margin: 3.125rem;
	}
}

.ewlCertificateChild {
	display: flex;
	width: 90%;
	align-items: center;

	@media screen and (min-width: 1000px) {
		width: 70%;
	}
}
.leftContainer {
	width: 40%;
}
.ewlText {
	font-size: 1.065rem;
	font-weight: 500;
	padding: 0.625rem 0;

	span {
		color: var(--ziroh_primary_color);
	}

	@media screen and (min-width: 730px) {
		font-size: 1.565rem;
		padding: 1.25rem 1.875rem;
	}
	@media screen and (min-width: 1000px) {
		font-size: 2rem;
	}
	@media screen and (min-width: 1400px) {
		font-size: 2.25rem;
	}
	@media screen and (min-width: 1700px) {
		font-size: 2.68rem;
	}
}
.ewlrightContainer {
	width: 58%;

	@media screen and (min-width: 1000px) {
		width: 50%;
	}
}
.ewlCarousel {
	width: 100%;
	height: 100%;
}
.ewlCertificates {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	img {
		width: 80%;
	}
}
.universitiesContainer {
	width: 100%;
	display: flex;
	justify-content: center;
	margin: 3.125rem 0;

	@media screen and (min-width: 730px) {
		margin: 6.25rem 0;
	}
}
.universitiesChild {
	width: 95%;
	border: 2px solid #f12a62;
	border-radius: 0.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem 0.5rem;
	position: relative;
	@media screen and (min-width: 730px) {
		width: 86.5%;
		padding: 2.5rem 1.25rem;
	}
}
.universityHeading {
	position: absolute;
	top: -9%;
	background-color: white;
	padding: 0 0.625rem;
	font-size: 1.2rem;
	font-weight: 500;

	@media screen and (min-width: 730px) {
		font-size: 1.68rem;
		top: -10%;
	}

	@media screen and (min-width: 1000px) {
		top: -12%;
		font-size: 1.8rem;
	}

	@media screen and (min-width: 1400px) {
		top: -11%;
	}

	@media screen and (min-width: 1700px) {
	}
}
.universityGrid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: none;
	grid-gap: 0.3rem;
	width: 100%;

	@media screen and (min-width: 730px) {
		grid-template-columns: repeat(5, 1fr);
		grid-gap: 0.5rem;
	}
	@media screen and (min-width: 1000px) {
		grid-gap: 0.6rem;
	}
	@media screen and (min-width: 1400px) {
		grid-gap: 0.9rem;
	}
	@media screen and (min-width: 1400px) {
		grid-gap: 1.1rem;
	}
}
.universityContent {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0.5rem;
	box-shadow: 0px 0px 8px 0px #00000040;
	border-radius: 0.5rem;

	img {
		width: 80%;
		aspect-ratio: 3/2;
		object-fit: contain;
	}
	@media screen and (min-width: 730px) {
		padding: 0.3125rem;
	}
}
.gridUniversityName {
	font-size: 0.6rem;
	font-weight: 500;
	text-align: center;
	margin-top: 1rem;

	@media screen and (min-width: 730px) {
		font-size: 0.6rem;
		margin-top: 0.625rem;
	}
	@media screen and (min-width: 1000px) {
		font-size: 0.7rem;
		margin-top: 0.625rem;
	}
	@media screen and (min-width: 1400px) {
		font-size: 0.8rem;
		margin-top: 0.625rem;
	}

}
.seeMore {
	background-color: #f12a62;
	border: none;
	outline: none;
	border-radius: 0.5rem;
	color: white;
	font-size: 0.68rem;
	padding: 0.31rem 1.875rem;
	position: absolute;
	bottom: -9%;
	box-shadow: 0px 0px 16px 1px #0000004d;

	@media screen and (min-width: 730px) {
		padding: 0.625rem 1.875rem;
	}
}
.seeMoreButton {
	background-color: #f12a62;
	border: none;
	outline: none;
	border-radius: 0.5rem;
	color: white;
	font-size: 1rem;

	@media screen and (min-width: 730px) {
		font-size: 0.94rem;
	}
}
.joinCommunityContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 3.125rem;
	width: 100%;
}
.communityAndRegisterContainer {
	display: flex;
	flex-direction: column-reverse;
	width: 95%;
	justify-content: space-between;
	align-items: center;

	@media screen and (min-width: 730px) {
		flex-direction: row;
		align-items: flex-start;
		width: 86.5%;
	}
}
.joincommunitychild {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	margin-top: 1.25rem;

	@media screen and (min-width: 730px) {
		margin-top: 0;
		width: 36%;
	}

	@media screen and (min-width: 1000px) {
		width: 42%;
	}
}
.communityHeading {
	font-size: 1.5rem;
	font-weight: 500;
	margin-bottom: 0.625rem;

	@media screen and (min-width: 730px) {
		font-size: 1.5625rem;
	}
	@media screen and (min-width: 1000px) {
		font-size: 2rem;
	}
}
.communityGrid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 0.7rem;
	padding: 0.125rem;
	grid-template-rows: auto;

	@media screen and (min-width: 730px) {
		gap: 0.49rem;
	}
	@media screen and (min-width: 1000px) {
		gap: 2rem 2.5rem;
	}
	@media screen and (min-width: 1400px) {
		gap: 2rem 3rem;
	}
	@media screen and (min-width: 1700px) {
		gap: 2rem 4rem;
	}
}
.communityContents {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 0px 0px 8px 0px #00000040;
	border-radius: 0.5rem;
	padding: 0.5rem;

	.communityIcons {
		width: 6rem;

		@media screen and (min-width: 730px) {
			width: 3.6875rem;
		}
		@media screen and (min-width: 1000px) {
			width: 6.8rem;
		}
		@media screen and (min-width: 1400px) {
			width: 8.06rem;
		}
		@media screen and (min-width: 1700px) {
			width: 9.875rem;
		}
	}
}
.communityTextContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-top: 0.43rem;
	font-size: 0.8125rem;
	font-weight: 500;

	@media screen and (min-width: 730px) {
		font-size: 0.625rem;
	}
	@media screen and (min-width: 1000px) {
		font-size: 0.9rem;
	}
	@media screen and (min-width: 1400px) {
	}
	@media screen and (min-width: 1700px) {
	}
}
.signUpFormContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;
	align-items: center;

	@media screen and (min-width: 730px) {
		width: 60%;
	}

	@media screen and (min-width: 1000px) {
		width: 42%;
	}
}
.signUpformModal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	padding-top: 0%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.sigmnupModalChild {
	width: 100%;

	@media screen and (min-width: 730px) {
		width: 58% !important;
	}

	@media screen and (min-width: 1000px) {
		width: 42% !important;
	}
}
